// imports
import React from "react"
import { withPrefix } from "gatsby"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import useScript from "../hooks/useScript"
import SEO from "../components/SEO"
import Navbar from "../components/Navbar"
import BackgroundDiv from "../components/BackgroundDiv"
import AboutSection from "../components/AboutSection"
import ResearchSection from "../components/ResearchSection"
import ProjectsSection from "../components/ProjectsSection"


function getYear() {
    return new Date().getFullYear();
}

export default function Home({ data }) {
    useScript(withPrefix("scripts/jquery.min.js"));
    useScript(withPrefix("scripts/jquery.waypoints.min.js"));
    useScript(withPrefix("scripts/waypoint.js"));
    useScript("https://www.googletagmanager.com/gtag/js?id=UA-159994104-1");
    useScript(withPrefix("scripts/google-analytics.js"));

    return (
        <div>
        <SEO title="Home" description={data.site.siteMetadata.description} />
        <div id="colorlib-page">
            <Navbar />

            {/* main page contents */}
            <div id="colorlib-main">
                {/* about section */}
                <BackgroundDiv className="hero-wrap">
                    <div className="overlay"></div>
                    <AboutSection data={data} divStyle={{}}>
                        <p style={{marginBottom: "3rem"}}><Link to="/about/" className="btn-custom section-link">More about me <span className="ion-ios-arrow-forward"></span></Link></p>
                    </AboutSection>
                </BackgroundDiv>
                {/* end of about section */}

                <ResearchSection data={data}>
                    <div className="row justify-content-center mb-0 pb-0 pt-3">
                        <div className="col-md-7 heading-section text-center ftco-animate">
                            <Link to="/research/" className="btn-custom section-link">More of my research <span className="ion-ios-arrow-forward"></span></Link>
                        </div>
                    </div>
                </ResearchSection>

                <ProjectsSection data={data} sectionBackgroundStyle={{background:"#f4f4f4"}}>
                    <div className="row justify-content-center mb-0 pb-0 pt-3">
                        <div className="col-md-7 heading-section text-center ftco-animate">
                            <Link to="/projects/" className="btn-custom section-link">More of my projects <span className="ion-ios-arrow-forward"></span></Link>
                        </div>
                    </div>
                </ProjectsSection>

                {/* footer */}
                <footer className="ftco-footer ftco-bg-dark ftco-section pt-3 pb-0">
                    <div className="container px-md-5">
                        <div className="row">
                            <div className="col-md-12">
                                {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                                <p className="colorlib-attribution">Copyright &copy; {getYear()} All rights reserved | This template is made with <i className="icon-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank" rel="noreferrer noopener">Colorlib</a></p>
                                {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                            </div>
                        </div>
                    </div>
                </footer>
                {/* end of footer */}
            </div>
            {/* end of #colorlib-main */}
        </div>
        {/* end of #colorlib-page */}
        </div>
    )
}


export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        about: allMarkdownRemark(
            filter: {
                frontmatter: {
                    contentType: {
                        in: ["about-me"]
                    }
                }
            }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        blurb
                    }
                    fields {
                        slug
                    }
                }
            }
        }
        research: allMarkdownRemark(
            limit: 3
            filter: {
                frontmatter: {
                    contentType: {
                        in: ["research"]
                    }
                }
            }
            sort: { 
                fields: [frontmatter___displayOrder]
                order: ASC 
            }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        displayOrder
                        featuredImage {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 800) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        title
                        topic
                        blurb
                    }
                    fields {
                        slug
                    }
                }
            }
        }
        projects: allMarkdownRemark(
            limit: 3
            filter: {
                frontmatter: {
                    contentType: {
                        in: ["project"]
                    }
                }
            }
            sort: { 
                fields: [frontmatter___displayOrder]
                order: ASC 
            }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        displayOrder
                        featuredImage {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 800) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        title
                        topic
                        blurb
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`