// imports
import React from "react"
import ProjectPreview from "./ProjectPreview"


export default function ProjectsSection({ data, sectionBackgroundStyle, children }) {
    return (
        <div>
            <div id='projects'></div>
            <section className="ftco-section pt-5 pb-5" style={sectionBackgroundStyle}>
                <div className="container">
                    <div className="row justify-content-center mb-3 pb-2">
                        <div className="col-md-7 heading-section text-center ftco-animate">
                            <h2 className="mb-4">Projects</h2>
                            <p>I've worked on side projects ranging from data science to shirt art design</p>
                        </div>
                    </div>
                    <div className="row" style={{textAlign:"center"}}>
                        {data.projects.edges.map(({ node }) => (
                            <ProjectPreview
                                key={node.id}
                                urlPath={node.fields.slug}
                                featuredImage={node.frontmatter.featuredImage}
                                featuredImageStyle={{height:"7.5em", width:"7.5em", display:"inline-block"}}
                                topic={node.frontmatter.topic} 
                                title={node.frontmatter.title}
                                content={node.frontmatter.blurb} 
                            />
                        ))}
                    </div>
                    {children}
                </div>
            </section>
        </div>
    )
}
