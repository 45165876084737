import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"


export default function ProjectPreview(props) {
    // logic to handle bitmap vs svg images
    var output
    if (props.featuredImage.childImageSharp) {
        output = <Img className="img img-2" fluid={props.featuredImage.childImageSharp.fluid} style={props.featuredImageStyle} />
    } else {
        output = <img className="img img-2" src={props.featuredImage.publicURL} style={props.featuredImageStyle} alt="project icon"></img>
    }
    return(
        <div className="col-md-4 mt-4" key={props.id}>
            <div className="blog-entry ftco-animate mb-3">
                <Link to={props.urlPath}>
                    {output}
                </Link>
                <div className="text text-2 pt-2 mt-3">
                    <span className="category mb-3 d-block"><Link to={props.urlPath}>{props.topic}</Link></span>
                    <h3 className="mb-3"><Link to={props.urlPath}>{props.title}</Link></h3>
                    <p className="mb-4">{props.content}</p>
                </div>
            </div>
        </div>
    )
}
