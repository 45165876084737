// imports
import React from "react"
import ProjectPreview from "./ProjectPreview"


export default function ResearchSection({ data, sectionBackgroundStyle, children }) {
    return (
        <div>
            <div id='research'></div>
            <section className="ftco-section pt-5 pb-5" style={sectionBackgroundStyle}>
                <div className="container">
                    <div className="row justify-content-center mb-2">
                        <div className="col-md-7 heading-section text-center ftco-animate">
                            <h2 className="mb-4">Research</h2>
                            <p>During my PhD, I worked with focused ultrasound, a technology used to perform non-invasive brain surgery in humans. I've also performed research in neurosurgery and nuclear medicine.</p>
                        </div>
                    </div>
                    <div id="research-row" className="row">
                        {data.research.edges.map(({ node }) => (
                            <ProjectPreview
                                key={node.id}
                                urlPath={node.fields.slug}
                                featuredImage={node.frontmatter.featuredImage}
                                featuredImageStyle={{maxHeight: "200px"}}
                                topic={node.frontmatter.topic} 
                                title={node.frontmatter.title}
                                content={node.frontmatter.blurb} 
                            />
                        ))}
                    </div>
                    {children}
                </div>
            </section>
        </div>
    )
}
